@charset "utf-8";

$header-font-family: "Museo", -apple-system, BlinkMacSystemFont, "Roboto", "Segoe UI", "Helvetica Neue", "Lucida Grande",
    Arial, sans-serif;

@import "minimal-mistakes/skins/default"; // skin
@import "minimal-mistakes"; // main partials
@import "museo.css";
@import "academicons.min.css";

html {
    font-size: 16px;
    @include breakpoint($medium) {
        font-size: 18px;
    }
    @include breakpoint($large) {
        font-size: 18px;
    }
    @include breakpoint($x-large) {
        font-size: 18px;
    }
}

.page__content p,
.page__content li,
.page__content dl {
    font-size: 0.9em;
}
.page__content dd {
    font-size: 1em;
}
.author__urls a {
    display: inline;
}
.btn.disabled,
.btn:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}
.collapse {
    display: none;
}

.btn--small,
.btn--small:visited {
    line-height: 14px;
    padding: 0.25em 0.5em;
}

ol.bibliography {
    li {
        margin-bottom: 20px;
    }
    list-style-type: none;
    padding: 0px;
    margin: 0px;
    pre {
        font-size: 80%;
        margin-bottom: 0px;
        padding: 6px 8px;
    }
    .highlight {
        background-color: #f8f8f8;
    }
    .btn,
    .btn:visited {
        @extend .btn--primary;
        @extend .btn--small;
        background-color: #aab9bd;
        line-height: 14px;
        padding: 0.25em 0.5em;
    }
    .btn:hover {
        background-color: #595f64;
    }
    .btn.disabled:hover {
        background-color: #aab9bd;
    }
}
h1 {
    font-size: 2.5rem;
    font-weight: 500;
    .btn {
        font-size: 11px;
    }
}
h2,
h2 a,
h2 a:visited {
    font-weight: 300;
    font-size: 32px;
    color: #dc322f;
    text-decoration: none;
}
h2 {
    margin: 1em 0 0.5em;
}
.sidebar__right ~ h2 {
    margin-top: 0px;
}
.page__content h2 {
    padding-bottom: 0em;
}
.page .page__inner-wrap {
    margin-top: 1.5em;
}

.language-bibtex {
    .nc {
        font-weight: bold;
        color: #46a;
    }
    .na {
        color: #4a8;
    }
    .o,
    .p {
        color: #888;
    }
    .s {
        color: #333;
    }
    .nl {
        color: #835;
    }
}
pre code {
    color: #e83e8c;
}

figure {
    img {
        margin-bottom: 0.5em;
    }
    margin: 0em 1em;
}
figure.align-right {
    img {
        width: auto;
        margin-left: auto;
        margin-right: auto;
    }
    max-width: 300px;
    text-align: center;
    figcaption {
        text-align: left;
    }
}
figcaption {
    font-family: $sans-serif;
}
.page__hero--overlay {
    background-position: top;
}
.page__hero-caption {
    font-family: $sans-serif;
}

.ai-google-scholar {
    color: #4285f4;
}
.ai-orcid {
    color: #a6ce39;
}

.masthead__menu-item {
    font-weight: bold;
    font-variant: small-caps;
}

.text-muted {
    color: #6c757d !important;
}
.greedy-nav {
    a.site-title {
        font-family: "MuseoSlab", $header-font-family;
        font-weight: bold;
        font-size: 200%;
        //color: #dc322f;
    }
    ul.visible-links {
        margin-right: 20px;
    }
}
.frontpage a.site-title {
    display: none;
}
.page__hero--overlay {
    padding: 4em 1em;
}

.card {
    padding-left: 1rem;
    padding-right: 1rem;
}
.sidebar {
    opacity: 1;
    .author__avatar img {
        max-width: 175px;
    }
    .author__content {
        vertical-align: middle;
    }
    .author__name {
        @include breakpoint($large) {
            margin-bottom: 1em;
        }
    }
    .author__urls {
        right: -2rem;
        @include breakpoint($large) {
            right: 0rem;
        }
    }
}
.toc {
    border: none;
    box-shadow: none;
    -webkit-box-shadow: none;
}
.toc__menu a {
    border: none;
}
.sidebar__right {
    @extend .sticky;
    display: none;
    // @include breakpoint($medium) { display: block; }
    @include breakpoint($large) {
        display: block;
    }
    @include breakpoint($x-large) {
        display: block;
    }
}
#page-title .btn {
    vertical-align: middle;
}

.frontpage .page__content {
    h3 {
        width: 3.8rem;
        margin: 0rem;
        display: inline-block;
        vertical-align: top;
        .header-link { display: none }
    }
    ul {
        display: inline-block;
        width: calc(100% - 4.7rem);
        vertical-align: top;
        margin: 0px 0px 0px 10px;
        list-style-type: none;
        list-style-position: outside;
        border-left: 1px solid #aaa;
        padding-left: 14px;
        li {
            padding-left: 16px;
            margin-bottom: 0em;
            padding-bottom: 0.5em;
        }
        li:before {
            content: '';
            width: 8px;
            height: 8px;
            background-color: #aaa;
            border: 3px solid #fff;
            border-radius: 8px;
            position: absolute;
            left: 4rem;
            margin-top: 6px;
        }
    }
}
